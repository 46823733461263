import React from 'react'
import { Link } from 'gatsby'
import './team.css'
import Layout from '../../components/layout'
import Walker from '../../images/Walker.jpg'
import Jeff from '../../images/jeff-williams.jpg'



class Team extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          tab41: false
          // Note: think carefully before initializing
          // state based on props!
          // someInitialValue: this.props.initialValue
        }
        this.toggle41 = this.toggle41.bind(this);
      }

toggle41() {
    this.setState(prevState => ({
        tab41: !prevState.tab41
      }));
}

render() {
return ( 
<Layout>
{/* <Header /> */}
<div className="team-content">
		
        <div className="jobs-grid">
            <div className="jobs section vision-section">
                <h2 className="team-header vision">Our Vision</h2>
                <h3 className="vision-subtext">Robots that build houses.</h3>
            </div>

            <div className="jobs section">
                <h2 className="team-header">Our Values</h2>
                <div className="core-values">
                    <ul>
                        <li>Boldness</li>
                        <li>Persistence</li>
                        <li>Hard Work</li>
                        <li className="content-clickable"  onClick={this.toggle41}>
                            4+1
                        </li>
                    </ul>
                    <ul>
                        <li>Integrity</li>
                        <li>Safety</li>
                        <li>Happiness</li>
                    </ul>
                </div>
            </div>

            {this.state.tab41 ? <div className="content-clicked">
            <div className="close" onClick={this.toggle41}>x</div>
            <h3>Why 4 + 1</h3>
            <p>
                We are at the cusp of robots doing things that were once considered science-fiction.
                Productivity will go through the roof.  Just as we no longer work 6 days on the farm, we will eventually add another day to the weekend.  Until then Williams Robotics will dedicate it’s employees to 4 days of work and 1 day of service.  That is to say we will work 4 days for the company and then give one day of service to the community – with 5 days of pay to the employee.
            </p>
        </div> : null }


        <p className="value-p">If you share our vision and values, we would love to hear from you. Email us at <a href="mailto:contact@williamsrobotics.com">contact@williamsrobotics.com</a></p>
        {/* <p className="value-p"> The following positions will round out our founding “flat sides.” </p> */}
      
        </div>

      
		


		<div className="team-member-flex-wrap">
			<div className="team-person-wrapper">
				<div className="team-person-img-wrapper">
					<img className="team-person-img" src={Jeff} />
				</div>

				<div className="team-person-content-wrapper">
					<h2>Jeff Williams</h2>
					<h3>Founder/Chief-Engineer</h3>
					<p>
				  	Jeff Williams is founder of Williams Robotics, LLC.  An Aeronautical Engineer, he has spent his entire career
						developing robotic solutions for non-industrial problems. Most recently, he co-founded Parata, a leading pharmacy robotics automation company which
						has deployed thousands of prescription dispensing robots to retail pharmacies around the world.
					</p>
			 </div>
			</div>

            <div className="team-person-wrapper">
                <div className="team-person-img-wrapper">
                    <img className="team-person-img" src={Walker}/>
                </div>

                <div className="team-person-content-wrapper">
                    <h2>Walker Harris</h2>
                    <h3>Co-founder & President</h3>
                    <p>
                    Walker is a natural leader,  well positioned to guide and communicate the objectives of Williams Robotics. With more than 25 years  experience as a custom homebuilder in Raleigh-Durham,  North Carolina, Walker is well versed in all phases of construction and is excited by the challenges and promise of robots in construction and their ability to mitigate labor shortages.
                    </p>
                    <p>
                        A graduate ot UNC-Chapel Hill with a BA in Economics, he launched Chase Building in 1995, and has built over 100 high-end homes throughout the Triangle.   He is a Certified Building Performance Analyst and has earned numerous awards in customer satisfaction, innovative design, and interior millwork. 
                    </p>
             </div>
            </div>

			{/* <!-- <div className="team-person-wrapper">
				<div className="team-person-img-wrapper">
					<img className="team-person-img" src="https://s3-us-west-2.amazonaws.com/williams-robotics/profile-pic-unknown.png">
				</div>

				<div className="team-person-content-wrapper">
					<h2>Opportunity for Co-Founder</h2>
					<h3>Co-Founder/Marketing</h3>
					<p>
						If you’re a construction professional and love to listen to customers — we need a lot of what you got.
					</p>
			 </div>
			</div> --> */}

				{/* <div className="team-person-wrapper">
                    <div className="team-person-img-wrapper">
                        <img className="team-person-img" src="https://s3-us-west-2.amazonaws.com/williams-robotics/profile-pic-unknown.png" />
                    </div>
                    <div className="team-person-content-wrapper">
                        <h2>Opportunity for Co-Founder</h2>
                        <h3>Co-Founder/Strategy</h3>
                        <p>
                            If you're a finance professional who relishes the challenge of bringing disruptive technology to market — we’ve got a role for you.
                        </p>
                    </div>
			    </div> */}
		</div>

	</div>
    {/* <Footer /> */}
    </Layout>
    )
    }
}

export default Team
